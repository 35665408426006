import { z } from 'zod';
import type { UIMatch } from '@remix-run/react';
import { useMatches } from '@remix-run/react';
import type { APHandle } from '../type';

type PreviewDisabledFeatures = z.infer<typeof DisabledFeaturesObjectSchema>;
type AllowedDisabledFeatureKey = z.infer<typeof FeatureKeys>;

const FeatureKeys = z.enum([
    'top_nav',
    'visit_site_button',
    'featured_tile_links',
    'photo_viewer',
    'save_design_board',
    'profile_social_links',
    'profile_send_enquiry',
    'profile_downloads',
    'video_playback',
    'contact_view_profile',
    'contact_enquire',
    'directory_sort',
    'footer',
    'article_view_profile',
] as const);

const DisabledFeaturesObjectSchema = z.record(FeatureKeys, z.boolean());

const DISABLED_PREVIEW_FEATURES: PreviewDisabledFeatures = {
    top_nav: true,
    visit_site_button: true,
    featured_tile_links: true,
    photo_viewer: true,
    save_design_board: true,
    profile_social_links: true,
    profile_send_enquiry: true,
    profile_downloads: true,
    video_playback: true,
    contact_view_profile: true,
    contact_enquire: true,
    directory_sort: true,
    footer: true,
    article_view_profile: true,
};

export const useIsDisabledInPreview = (
    feature: AllowedDisabledFeatureKey
): boolean => {
    const matches = useMatches();
    const isPreview = matches.some(
        (match) => (match as UIMatch<unknown, APHandle>)?.handle?.isBMPreview
    );

    if (!isPreview) {
        return false;
    }

    /*
     *  We intentionally blow up if the feature key is not recognized.
     *  Because we use the AllowedDisabledFeatureKey type for the feature arg
     *  any typos should be picked up by typescript, but just in case we will throw a more readable error.
     */
    const safeParsedFeatureKey = FeatureKeys.safeParse(feature);
    if (!safeParsedFeatureKey.success) {
        throw new Error(
            `Invalid feature key (${feature}) passed to useIsDisabledInPreview. Valid keys are: ${Object.keys(
                DISABLED_PREVIEW_FEATURES
            ).join(', ')}`
        );
    }
    const featureKey = safeParsedFeatureKey.data;

    const leafRouteMatch = matches[matches.length - 1] as UIMatch<
        unknown,
        APHandle
    >;

    const safeParsedHandleOverrides = DisabledFeaturesObjectSchema.safeParse(
        leafRouteMatch?.handle?.disabledInPreview
    );

    const handleOverrides = safeParsedHandleOverrides.success
        ? safeParsedHandleOverrides.data
        : {};

    const disabledInPreview = {
        ...DISABLED_PREVIEW_FEATURES,
        ...handleOverrides,
    };

    return disabledInPreview[featureKey] ?? false;
};

export type { PreviewDisabledFeatures };
